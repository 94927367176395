
<template>
  <div>
    <header1Component
      :circleActive="1"
      :detailActive="1"
      :loopCircleSmall="4"
      :circleSmall="0"
      :changeCircle="0"
      :changeTitle="0"
      titleHeader="หัวข้อ : 2.รายละเอียดและข้อเสนอโครงการ"
    />
    <div class="container">
      <div class="card2">
        <div class="conditionCard2">
          <div class="title">
            หลักการและเหตุผลความจำเป็น <font color="red">*</font>
          </div>
          <textarea
            v-model="principlesReasonsNecessity"
            placeholder="กรอก"
          ></textarea>
        </div>

        <div class="conditionCard2">
          <div class="title">
            วัตถุประสงค์โครงการ <font color="red">*</font>
          </div>

          <div
            class="flex"
            v-for="(item, index) in projectObjectives"
            :key="index"
          >
            <div
              class="content flex items-center ml-6 mr-12"
              style="height: 50px; width: 10px"
            >
              {{ index + 1 }}.
            </div>

            <div class="w-full" style="margin-bottom: 16px">
              <input
                type="text"
                placeholder="กรอก"
                v-model="projectObjectives[index].name"
              />
            </div>

            <div
              v-if="index === 0"
              class="ml-6 flex flex-row items-center"
              style="height: 50px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                style="background-color: white"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  color="white"
                />
              </div>
            </div>

            <div
              v-else
              class="ml-6 flex flex-row items-center"
              style="height: 50px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                :style="`background-color: red;`"
                @click="deleteProjectObjectives(index)"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>
            </div>
          </div>

          <div
            class="flex flex-row items-center justify-start ml-20"
            style="height: 50px"
          >
            <div
              class="
                circle
                flex
                items-center
                justify-center justify-items-center
              "
              style="margin-right: 16px"
              @click="addProjectObjectives()"
            >
              <font-awesome-icon
                style="width: 18px; height: 23px"
                icon="plus"
                color="white"
              />
            </div>

            <div class="titleCircle flex items-center">
              เพิ่มวัตถุประสงค์โครงการ
            </div>
          </div>
        </div>

        <div class="conditionCard2" style="margin-top: 27px">
          <div class="title">เป้าหมายของโครงการ <font color="red">*</font></div>

          <div class="flex" v-for="(item, index) in projectGols" :key="index">
            <div
              class="content flex items-center ml-6 mr-12"
              style="height: 50px; width: 10px"
            >
              {{ index + 1 }}.
            </div>

            <div class="w-full" style="margin-bottom: 16px">
              <input
                type="text"
                placeholder="กรอก"
                v-model="projectGols[index].name"
              />
            </div>

            <div
              v-if="index === 0"
              class="ml-6 flex flex-row items-center"
              style="height: 50px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                style="background-color: white"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  color="white"
                />
              </div>
            </div>

            <div
              v-else
              class="ml-6 flex flex-row items-center"
              style="height: 50px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                :style="`background-color: red;`"
                @click="deleteProjectGols(index)"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>
            </div>
          </div>

          <div
            class="flex flex-row items-center justify-start ml-20"
            style="height: 50px"
          >
            <div
              class="
                circle
                flex
                items-center
                justify-center justify-items-center
              "
              style="margin-right: 16px"
              @click="addprojectGols()"
            >
              <font-awesome-icon
                style="width: 18px; height: 23px"
                icon="plus"
                color="white"
              />
            </div>

            <div class="titleCircle flex items-center">
              เพิ่มเป้าหมายของโครงการ
            </div>
          </div>
        </div>

        <hr />

        <div class="conditionCard2" style="margin-top: 27px">
          <div class="title" style="margin-bottom: 7px">กลุ่มเป้าหมาย</div>

          <div class="content" style="margin-bottom: 13px">
            กลุ่มเป้าหมายหลัก <font color="red">*</font>
          </div>

          <div class="flex" v-for="(item, index) in targetGroup" :key="index">
            <div
              class="content flex items-center ml-6 mr-12"
              style="height: 50px; width: 10px"
            >
              {{ index + 1 }}.
            </div>

            <div class="w-full" style="margin-bottom: 16px">
              <input
                type="text"
                placeholder="กรอก"
                v-model="targetGroup[index].name"
              />
            </div>

            <div class="flex flex-row items-center" style="height: 50px">
              <div class="content ml-12 mr-2">จำนวน</div>
              <div>
                <input
                  type="text"
                  placeholder="0"
                  v-model="targetGroup[index].total"
                />
              </div>
              <div class="ml-4 content">คน</div>
            </div>

            <div
              v-if="index === 0"
              class="ml-6 flex flex-row items-center"
              style="height: 50px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                style="background-color: white"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  color="white"
                />
              </div>
            </div>

            <div
              v-else
              class="ml-6 flex flex-row items-center"
              style="height: 50px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                :style="`background-color: red;`"
                @click="deleteTargetGroup(index)"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>
            </div>
          </div>

          <div
            class="flex flex-row items-center justify-start ml-20"
            style="height: 50px"
          >
            <div
              class="
                circle
                flex
                items-center
                justify-center justify-items-center
              "
              style="margin-right: 16px"
              @click="addTargetGroup()"
            >
              <font-awesome-icon
                style="width: 18px; height: 23px"
                icon="plus"
                color="white"
              />
            </div>

            <div class="titleCircle flex items-center">เพิ่มเป้าหมายหลัก</div>
          </div>
        </div>

        <div class="conditionCard2" style="margin-top: 27px">
          <div class="content" style="margin-bottom: 13px">
            กลุ่มเป้าหมายรอง <font color="red">*</font>
          </div>

          <div
            class="flex"
            v-for="(item, index) in subTargetGroup"
            :key="index"
          >
            <div
              class="content flex items-center ml-6 mr-12"
              style="height: 50px; width: 10px"
            >
              {{ index + 1 }}.
            </div>

            <div class="w-full" style="margin-bottom: 16px">
              <input
                type="text"
                placeholder="กรอก"
                v-model="subTargetGroup[index].name"
              />
            </div>

            <div class="flex flex-row items-center" style="height: 50px">
              <div class="content ml-12 mr-2">จำนวน</div>
              <div>
                <input
                  type="text"
                  placeholder="0"
                  v-model="subTargetGroup[index].total"
                />
              </div>
              <div class="ml-4 content">คน</div>
            </div>

            <div
              v-if="index === 0"
              class="ml-6 flex flex-row items-center"
              style="height: 50px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                style="background-color: white"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  color="white"
                />
              </div>
            </div>

            <div
              v-else
              class="ml-6 flex flex-row items-center"
              style="height: 50px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                :style="`background-color: red;`"
                @click="deleteSubTargetGroup(index)"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>
            </div>
          </div>

          <div
            class="flex flex-row items-center justify-start ml-20"
            style="height: 50px"
          >
            <div
              class="
                circle
                flex
                items-center
                justify-center justify-items-center
              "
              style="margin-right: 16px"
              @click="addSubTargetGroup()"
            >
              <font-awesome-icon
                style="width: 18px; height: 23px"
                icon="plus"
                color="white"
              />
            </div>

            <div class="titleCircle flex items-center">เพิ่มเป้าหมายรอง</div>
          </div>
        </div>

        <div class="conditionCard2" style="margin-top: 27px">
          <div class="title" style="margin-bottom: 7px">
            ความพร้อมในการดำเนินงาน
          </div>

          <div v-for="(item, index) in checkboxs1" :key="index">
            <checkboxComponent
              class="mb-2"
              :checkboxId="`checkbox1_${index}`"
              :name="`${item}`"
              :value="`${item}`"
              @click="checkboxCondition1(`checkbox1_${index}`)"
            />
          </div>
        </div>

        <div class="conditionCard2" style="margin-top: 27px">
          <div class="title" style="margin-bottom: 7px">
            การกำหนดรายละเอียดค่าใช้จ่าย
          </div>

          <div v-for="(item, index) in checkboxs2" :key="index">
            <checkboxComponent
              class="mb-2"
              :checkboxId="`checkbox2_${index}`"
              :name="`${item}`"
              :value="`${item}`"
              @click="checkboxCondition2(`checkbox2_${index}`)"
            />
          </div>
        </div>

        <hr />

        <div class="conditionCard2" style="margin-top: 27px">
          <div class="title" style="margin-bottom: 7px">
            ขอบเขต และกิจกรรมการดำเนินงาน (ระบุขอบเขตของการทำโครงการ
            ว่ามีกิจกรรมสำคัญอะไรบ้าง มีการ
            ดำเนินการภายใต้เงื่อนไขใดบ้างหรือภายใต้ข้อจำกัดใดบ้าง
            กลุ่มเป้าหมายคือใครวิธีดำเนินการ) <font color="red">*</font>
          </div>

          <div>
            <textarea
              v-model="scopeOperatingActivities"
              placeholder="กรอก"
            ></textarea>
          </div>

          <div class="title" style="margin-bottom: 27px">
            วิธีการ/ขั้นตอนการดำเนินงานโครงการ <font color="red">*</font>
          </div>

          <div class="grid grid-cols-5 gap-4">
            <div class="content" style="color: #242424">
              รายละเอียดกิจกรรม <font color="red">*</font>
            </div>
            <div class="content" style="color: #242424">
              ผลผลิต <font color="red">*</font>
            </div>
            <div class="content" style="color: #242424">
              ตัวชี้วัด <font color="red">*</font>
            </div>
            <div class="content col-span-2" style="color: #242424">
              ระยะเวลา(วัน) เริ่มต้น-สิ้นสุด <font color="red">*</font>
            </div>
          </div>
          <hr style="margin-left: 0px; margin-right: 0px; margin-top: 0px" />

          <div
            class="grid grid-cols-5 gap-4"
            style="margin-bottom: 16px"
            v-for="(item, index) in methodsImplementingProject"
            :key="index"
          >
            <div class="">
              <input
                type="text"
                placeholder="กรอก"
                v-model="methodsImplementingProject[index].activityName"
              />
            </div>

            <div class="">
              <input
                type="text"
                placeholder="กรอก"
                v-model="methodsImplementingProject[index].product"
              />
            </div>

            <div class="">
              <input
                type="text"
                placeholder="กรอก"
                v-model="methodsImplementingProject[index].indicators"
              />
            </div>

            <div class="flex flex-row">
              <div class="mr-4">
                <input
                  type="text"
                  placeholder="0"
                  v-model="methodsImplementingProject[index].startDay"
                />
              </div>

              <div>
                <input
                  type="text"
                  placeholder="0"
                  v-model="methodsImplementingProject[index].endDay"
                />
              </div>
            </div>

            <div>
              <div
                v-if="index === 0"
                class="ml-6 flex flex-row items-center"
                style="height: 50px"
              >
                <div
                  class="
                    circle
                    flex
                    items-center
                    justify-center justify-items-center
                  "
                  style="background-color: white"
                >
                  <font-awesome-icon
                    style="width: 18px; height: 23px"
                    color="white"
                  />
                </div>
              </div>

              <div
                v-else
                class="ml-6 flex flex-row items-center"
                style="height: 50px"
              >
                <div
                  class="
                    circle
                    flex
                    items-center
                    justify-center justify-items-center
                  "
                  :style="`background-color: red;`"
                  @click="deleteMethodsImplementingProject(index)"
                >
                  <font-awesome-icon
                    style="width: 18px; height: 23px"
                    icon="minus"
                    color="white"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex flex-row items-center justify-start"
            style="height: 50px; margin-top: 17px"
          >
            <div
              class="
                circle
                flex
                items-center
                justify-center justify-items-center
              "
              style="margin-right: 16px"
              @click="addMethodsImplementingProject()"
            >
              <font-awesome-icon
                style="width: 18px; height: 23px"
                icon="plus"
                color="white"
              />
            </div>

            <div class="titleCircle flex items-center">
              เพิ่มขั้นตอน/กิจกรรม
            </div>
          </div>
        </div>

        <hr />

        <div class="conditionCard2">
          <div class="title">
            ความเสี่ยงของโครงการ <font color="red">*</font>
          </div>

          <div class="flex" v-for="(item, index) in projectRisk" :key="index">
            <div
              class="content flex items-center ml-6 mr-12"
              style="height: 50px; width: 10px"
            >
              {{ index + 1 }}.
            </div>

            <div class="w-full" style="margin-bottom: 16px">
              <input
                type="text"
                placeholder="กรอก"
                v-model="projectRisk[index].name"
              />
            </div>

            <div
              v-if="index === 0"
              class="ml-6 flex flex-row items-center"
              style="height: 50px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                style="background-color: white"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  color="white"
                />
              </div>
            </div>

            <div
              v-else
              class="ml-6 flex flex-row items-center"
              style="height: 50px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                :style="`background-color: red;`"
                @click="deleteProjectRisk(index)"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>
            </div>
          </div>

          <div
            class="flex flex-row items-center justify-start ml-20"
            style="height: 50px"
          >
            <div
              class="
                circle
                flex
                items-center
                justify-center justify-items-center
              "
              style="margin-right: 16px"
              @click="addProjectRisk()"
            >
              <font-awesome-icon
                style="width: 18px; height: 23px"
                icon="plus"
                color="white"
              />
            </div>

            <div class="titleCircle flex items-center">
              เพิ่มวัตถุประสงค์โครงการ
            </div>
          </div>
        </div>

        <div class="conditionCard2">
          <div class="title" style="margin-top: 31px">
            รายงานสภาพการแข่งขันในตลาด
            <font style="text-decoration: underline">
              (กรุณาดาวน์โหลดแบบฟอร์มก่อน)
            </font>
            <font color="red">*</font>
          </div>

          <div>
            <checkboxComponent
              class="mb-2"
              :checkboxId="`checkbox3_0`"
              :name="`มีรายงานการแข่งขัน`"
              :value="`มีรายงานการแข่งขัน`"
              @click="checkboxCondition3(`checkbox3_0`)"
            />

            <checkboxComponent
              class="mb-2"
              :checkboxId="`checkbox3_1`"
              :name="`ไม่มีรายงานการแข่งขัน`"
              :value="`ไม่มีรายงานการแข่งขัน`"
              @click="checkboxCondition3(`checkbox3_1`)"
            />
          </div>

          <div class="noteContent" style="margin-top: 12px">
            ท่านได้ให้การรับรองและรับประกันว่าปัจจุบัน
            <div style="text-indent: 1.5em">
              -ไม่มีตลาดของสินค้าหรือบริการที่ใช้ทดแทนกันได้กับที่ท่านเสนอในโครงการ
            </div>
            <div style="text-indent: 1.5em">
              -ไม่มีผู้ผลิตหรือผู้ประกอบการรายใดที่เสนอสินค้าหรือบริการที่ใช้ทดแทนกันได้กับที่ท่านเสนอในโครงการ
            </div>
            <div style="text-indent: 1.5em">
              -การดำเนินการโครงการของท่านจะไม่ส่งผลกระทบทางการแข่งขันในตลาดที่เกี่ยวข้อง
            </div>
          </div>

          <div class="content">หมายเหตุ :</div>
          <div>
            <li style="text-indent: 1.5em">
              แนะนำให้พยายามกรอแบบฟอร์มให้มากที่สุดและจะเป็นประโยชน์ต่อการพิจารณามากกว่าหากสามารถอธิบายว่ามีการแข่งขันหรือผู้ประกอบการรายอื่นที่พอ
            </li>
            <div style="text-indent: 3em">
              จะใช้ทดแทนได้ แต่โครงการที่เสนอมีข้อแตกต่างที่ดีกว่า
              และจะไม่ส่งผลกระทบต่อการแข่งขัน
            </div>
            <li style="text-indent: 1.5em">
              หากกองทุนตรวจสอบพบว่าไม่เป็นความจริงตามที่ท่านรับรองและรับประกันข้างต้นท่านรับทราบและเข้าใจดีว่าโครงการที่เสนออาจถูกตัดออกจากการ
              พิจารณาทันที
            </li>
          </div>
        </div>

        <div class="flex flex-row">
          <div
            class="w-1/2 flex flex-start justify-start"
            style="margin-left: 72px; margin-top: 81px"
          >
            <button class="buttonPrevious" @click="createRequest()">
              ก่อนหน้า
            </button>
          </div>

          <div
            class="w-1/2 flex items-end justify-end"
            style="margin-right: 72px; margin-top: 81px"
          >
            <button class="buttonNext" @click="updateRequest()">ถัดไป</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useRequest from "../../hooks/useRequest";
import header1Component from "@/components/capital/header1Component";
import checkboxComponent from "@/components/checkbox/checkboxComponent";
export default {
  components: {
    header1Component,
    checkboxComponent,
  },
  mounted() {
    this.getData();
  },
  methods: {
    addProjectObjectives() {
      this.projectObjectives.push({
        name: null,
      });
    },
    deleteProjectObjectives(id) {
      this.projectObjectives.splice(id, 1);
    },
    addprojectGols() {
      this.projectGols.push({
        name: null,
      });
    },
    deleteProjectGols(id) {
      this.projectGols.splice(id, 1);
    },
    addTargetGroup() {
      this.targetGroup.push({
        name: null,
        total: null,
      });
    },
    deleteTargetGroup(id) {
      this.targetGroup.splice(id, 1);
    },
    addSubTargetGroup() {
      this.subTargetGroup.push({
        name: null,
        total: null,
      });
    },
    deleteSubTargetGroup(id) {
      this.subTargetGroup.splice(id, 1);
    },
    checkboxCondition1(id) {
      try {
        for (let i = 0; i !== this.checkboxs1.length; i++) {
          let checkboxId = document.getElementById(`checkbox1_${i}`);
          if (id === `checkbox1_${i}`) {
            checkboxId.checked = true;
            this.operationalReadiness = checkboxId.value;
          } else {
            checkboxId.checked = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkboxCondition2(id) {
      try {
        for (let i = 0; i !== this.checkboxs2.length; i++) {
          let checkboxId = document.getElementById(`checkbox2_${i}`);
          if (id === `checkbox2_${i}`) {
            checkboxId.checked = true;
            this.expenseBreakdown = checkboxId.value;
          } else {
            checkboxId.checked = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkboxCondition3(id) {
      try {
        if (id === `checkbox3_0`) {
          document.getElementById("checkbox3_0").checked = true;
          document.getElementById("checkbox3_1").checked = false;
          this.marketCompetitionReport = "มีรายงานการแข่งขัน";
        } else if (id === 'checkbox3_1') {
          document.getElementById("checkbox3_0").checked = false;
          document.getElementById("checkbox3_1").checked = true;
          this.marketCompetitionReport = "ไม่มีรายงานการแข่งขัน";
        }
      } catch (error) {
        console.log(error);
      }
    },
    addMethodsImplementingProject() {
      this.methodsImplementingProject.push({
        activityName: null,
        product: null,
        indicators: null,
        startDay: null,
        endDay: null,
      });
    },
    deleteMethodsImplementingProject(id) {
      this.methodsImplementingProject.splice(id, 1);
    },
    addProjectRisk() {
      this.projectRisk.push({
        name: null,
      });
    },
    deleteProjectRisk(id) {
      this.projectRisk.splice(id, 1);
    },
    updateRequest() {
      let data = {
        principlesReasonsNecessity: this.principlesReasonsNecessity,
        projectObjectives: this.projectObjectives,
        projectGols: this.projectGols,
        targetGroup: this.targetGroup,
        subTargetGroup: this.subTargetGroup,
        operationalReadiness: this.operationalReadiness,
        expenseBreakdown: this.expenseBreakdown,
        scopeOperatingActivities: this.scopeOperatingActivities,
        methodsImplementingProject: this.methodsImplementingProject,
        projectRisk: this.projectRisk,
        marketCompetitionReport: this.marketCompetitionReport,
        pageStatus: 4,
      };
      useRequest.updateProject(this.id, data).then(response => {
        console.log(response.data)
      });
    },
    getData() {
      useRequest.getProjectId(this.id).then(response => {
        this.principlesReasonsNecessity = response.data.principlesReasonsNecessity;
        this.projectObjectives = response.data.projectObjectives;
        this.projectGols = response.data.projectGols;
        this.targetGroup = response.data.targetGroup;
        this.subTargetGroup = response.data.subTargetGroup;
        
        this.operationalReadiness = response.data.operationalReadiness;
        for (let i = 0; i !== this.checkboxs1.length; i++) {
            if (this.checkboxs1[i] === this.operationalReadiness) {
                this.checkboxCondition1(`checkbox1_${i}`);
            }
        }

        this.expenseBreakdown = response.data.expenseBreakdown;
        for (let i = 0; i !== this.checkboxs2.length; i++) {
            if (this.checkboxs2[i] === this.expenseBreakdown) {
                this.checkboxCondition2(`checkbox2_${i}`);
            }
        }

        this.scopeOperatingActivities = response.data.scopeOperatingActivities;
        this.methodsImplementingProject = response.data.methodsImplementingProject;
        this.projectRisk = response.data.projectRisk;

        this.marketCompetitionReport = response.data.marketCompetitionReport;
        if (this.marketCompetitionReport === 'มีรายงานการแข่งขัน') {
          this.checkboxCondition3('checkbox3_0');
        } else if (this.marketCompetitionReport === 'ไม่มีรายงานการแข่งขัน') {
          this.checkboxCondition3('checkbox3_1');
        }

      });
    },
  },
  setup() {
    const principlesReasonsNecessity = ref(null);
    const projectObjectives = ref([
      {
        name: null,
      },
    ]);
    const projectGols = ref([
      {
        name: null,
      },
    ]);
    const targetGroup = ref([
      {
        name: null,
        total: null,
      },
    ]);
    const subTargetGroup = ref([
      {
        name: null,
        total: null,
      },
    ]);
    const operationalReadiness = ref(null);
    const expenseBreakdown = ref(null);
    const checkboxs1 = ref(["ดำเนินการได้ทันที", "อยู่ระหว่างเตรียมความพร้อม"]);
    const checkboxs2 = ref([
      "เป็นไปตามหลักเกณฑ์ และอยู่ในเกณฑ์ราคามาตรฐานของทางราชการ",
      "ไม่เป็นไปตามหลักเกณฑ์ และสูงกว่าเกณฑ์ราคามาตรฐานของทางราชการ",
    ]);
    const scopeOperatingActivities = ref(null);
    const methodsImplementingProject = ref([
      {
        activityName: null,
        product: null,
        indicators: null,
        startDay: null,
        endDay: null,
      },
    ]);
    const projectRisk = ref([
      {
        name: null,
      },
    ]);
    const marketCompetitionReport = ref(null);
    return {
      principlesReasonsNecessity,
      projectObjectives,
      projectGols,
      targetGroup,
      subTargetGroup,
      operationalReadiness,
      checkboxs1,
      expenseBreakdown,
      checkboxs2,
      scopeOperatingActivities,
      methodsImplementingProject,
      projectRisk,
      marketCompetitionReport,
    };
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  }
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.card2 {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  padding-top: 34px;
  padding-bottom: 36px;
  margin-bottom: 36px;
}

.conditionCard2 {
  margin-left: 73px;
  margin-right: 73px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;

  color: #242424;
  margin-bottom: 10px;
}

hr {
  margin-left: 33px;
  margin-right: 33px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #c4c4c4;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #575757;
}

.contentActive {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
  width: 300px;
  margin-right: 24px;
  margin-left: 48px;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

button {
  width: 195px;
  height: 50px;
  color: white;
  background: #573c81;
  border-radius: 5px;
}

.buttonPrevious {
  width: 195px;
  height: 50px;
  color: #573c81;
  background: #ffffff;
  border: 1px solid #573c81;
  box-sizing: border-box;
  border-radius: 5px;
}

textarea {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 105px;
  padding-left: 18px;
  padding-top: 10px;
  resize: none;
}

.circle {
  width: 40px;
  height: 40px;
  color: white;
  background: #573c81;
  border-radius: 100%;
  margin-top: 36px;
  margin-bottom: 36px;
  font-size: 36px;
}

.titleCircle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
}

.noteContent {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #242424;
}
</style>